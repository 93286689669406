'use strict';

$(function () {
    $('#keqKidsContentModal').on('show.bs.modal', function (event) {
        console.log(event);
        const $benefit = $(event.relatedTarget);
        const benefitTitle = $benefit.find('[data-js="benefit-title"]').html();
        const benefitDescription = $benefit.find('[data-js="benefit-description"]').html();

        const $modalTitle = $(this).find('[data-js="modal-tile"]');
        const $modalDescription = $(this).find('[data-js="modal-description"]');

        $modalTitle.html(benefitTitle);
        $modalDescription.html(benefitDescription);
    }).on('hidden.bs.modal', function (event) {
        $(this).find('[data-js="modal-tile"]').html('');
        $(this).find('[data-js="modal-description"]').html('');
    });
});
